import {
  KameleonPrimaryComponent,
  KameleonFooterComponent,
  KameleonHeaderComponent,
} from "kameleon-elements";
import { BlockType } from "@kameleon-core/types";
import { DynamicLoaderProps } from "./types";

export const ZilchComponentFactory = ({
  component,
  blockProps,
  type,
  widgets,
}: DynamicLoaderProps) => {
  const args = {
    element: component,
    // States whether to fallback on defaultProps in case of fully-empty props
    mergeWithDefaults: process.env.NODE_ENV === "development",
    kameleonProps: { blockProps },
  };

  switch (type) {
    case BlockType.Footer: {
      // Infer props dynamically to make sure the args are built correctly
      const props: Parameters<typeof KameleonFooterComponent>[0] = { ...args };
      props.kameleonProps.widgetBlocks = widgets;
      return KameleonFooterComponent(props);
    }
    case BlockType.Header: {
      // Infer props dynamically to make sure the args are built correctly
      const props: Parameters<typeof KameleonHeaderComponent>[0] = { ...args };
      props.kameleonProps.widgetBlocks = widgets;
      return KameleonHeaderComponent(props);
    }
    default: {
      // Infer props dynamically to make sure the args are built correctly
      const props: Parameters<typeof KameleonPrimaryComponent>[0] = { ...args };
      return KameleonPrimaryComponent(props);
    }
  }
};
