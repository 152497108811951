import React, { Suspense, useState } from "react";
import { Shared } from "@kameleon-core/lib";

type FormProps = Shared.Props.FormProps;
export type ContactFormHocProps = {
  blockProps: {
    _contactFormSettings: {
      formId: number;
      postUrl: string;
    };
  } & FormProps &
    any;
  ContactFormComponent: React.JSX.Element;
};

/**
 * HOC to wrap a component in a Suspense component.
 */
export function ContactFormHoc({
  blockProps,
  ContactFormComponent,
}: ContactFormHocProps): React.JSX.Element {
  const formProps: FormProps & {} = { ...blockProps };
  // eslint-disable-next-line no-underscore-dangle
  const contactFormSettings = blockProps._contactFormSettings;

  const originalErrorMsg =
    !formProps.submitMsgError || formProps.submitMsgError.length <= 0
      ? "Er is een onbekende fout opgetreden"
      : formProps.submitMsgError;
  const originalSuccessMsg =
    !formProps.submitMsgSuccess || formProps.submitMsgSuccess.length <= 0
      ? "De aanvraag is verstuurd"
      : formProps.submitMsgSuccess;

  const [success, setSuccessState] = useState<string>("");
  const [error, setErrorState] = useState<string>("");

  const setSuccess = () => {
    setSuccessState(originalSuccessMsg);
    setErrorState("");
  };

  const setError = (err: any = undefined) => {
    if (err !== undefined) console.error(err);
    setErrorState(originalErrorMsg);
    setSuccessState("");
  };

  const onSubmit: FormProps["onSubmit"] = (inputValues, onComplete) => {
    const { formId, postUrl } = contactFormSettings;
    const bodyFormData = new FormData();
    bodyFormData.set(
      "_wpcf7_unit_tag", // Required by "submit" to `contactForm7` feedback URL
      `wpcf7-${formId}-${Math.floor(Math.random() * 10000)}`
    );

    formProps.inputs?.forEach((input, i) => {
      bodyFormData.set(
        input.name,
        inputValues ? (inputValues as any)?.[i] ?? "" : ""
      );
    });

    fetch(`${postUrl}`, {
      method: "POST",
      body: bodyFormData,
    })
      .then((response) => {
        response.json().then((json) => {
          if (json?.status !== "mail_sent") {
            setError(json);
            onComplete?.(false);
          } else {
            setSuccess();
            onComplete?.(true);
          }
        });
      })
      .catch((e) => {
        setError(e);
        onComplete?.(false);
      });
  };

  formProps.onSubmit = onSubmit;
  formProps.submitMsgError = error;
  formProps.submitMsgSuccess = success;

  const newBlockProps = {
    ...ContactFormComponent.props,
    ...{ blockProps: { blockProps: { ...formProps } } },
  };
  return (
    <Suspense fallback={<div />}>
      {React.cloneElement(ContactFormComponent, newBlockProps)}
    </Suspense>
  );
}
